/* eslint-disable react-hooks/exhaustive-deps */
import urlSound from "@Assets/audio/gf-noti.mp3";
import NewOrder from "@Assets/images/new-order.png";
import ProNull from "@Assets/images/nopro.png";
import { EventIcon } from "@Components/Icons/EventIcon";
import Loading from "@Components/Loading";
import Notification from "@Components/Notification";
import ProductItem from "@Components/ProductItem";
import QuantityBtnGroup from "@Components/QuantityBtnGroup";
import SimpleModalHeader from "@Components/SimpleModalHeader";
import { APP_ROUTES, OrderStatusLab, REGEX, TEXT_ASTERISK, TEXT_HOLDER } from "@Constants";
import {
  Action,
  AuthCustomer,
  CustomerGroupOutputModel,
  FetchAction,
  ItemGroupOutputModel,
  ItemOutputModel,
  ItemPermission,
  OrderDetailLabOutputModel,
  OrderInputModel,
  PaginateFetchReducer,
  ProductOutputModel,
  Reason,
  SourceType,
} from "@Models";
import {
  CustomerService,
  ObjectiveService,
  OrderService,
  ProductService,
  TargetService,
} from "@Services";
import { currencyFormat, FormUtil, PermissionUtil } from "@Utils";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  ArrowLeftSharp as ArrowLeftSharpIcon,
  ArrowRightSharp as ArrowRightSharpIcon,
  Clear as ClearIcon,
  ContentCopy,
  DeleteOutline as DeleteOutlineIcon,
  PriorityHighRounded as PriorityHighRoundedIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Backdrop,
  Dialog,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { vi } from "date-fns/locale";
import _ from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useHistory, useParams } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "../../OrderModal.scss";

SwiperCore.use([Navigation]);

function reducer(state: PaginateFetchReducer<ProductOutputModel>, action: FetchAction) {
  switch (action.type) {
    case Action.FETCH:
      return { ...state, fetching: true };
    case Action.SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: [...state.data, ...action.payload.data],
        loading: false,
      };
    case Action.RESET:
      return {
        ...state,
        ...action.payload,
        fetching: false,
        data: action.payload.data,
        loading: false,
      };
    case Action.FAILED:
      return { ...state, fetching: false, failMessage: action.payload };
    default:
      return { ...state };
  }
}

type OrderProduct = ProductOutputModel;

const UpdateOrderLab: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  //notification sound
  const [audio] = useState<any>(new Audio(urlSound));
  const [playing, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const mounted = useRef(false);
  const submitRef = useRef<any | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [notiOrderCancel, setNotiOrderCancel] = useState<boolean>(false);
  const { orderId }: { orderId: string } = useParams();
  const [order, setOrder] = useState<OrderDetailLabOutputModel | null>(null);
  const [interestedSuccess, setInterestedSuccess] = useState<boolean>(false);
  const [ordDraft, setOrdDraft] = useState<boolean>(false);

  const [selectedVars, setSelectedVars] = useState<{ item: OrderProduct; quantity: number }[]>([]);
  //luu tam cho mang selectVars
  const [tempSelectedVars, setTempSelectedVars] = useState<
    { item: OrderProduct; quantity: number }[]
  >([]);
  //index mang selectVars khi add tac nhan
  const [dialogID, setDialogID] = useState<string | number | null>(null);
  //target
  const [dataTarget, setDataTarget] = useState<CustomerGroupOutputModel[]>([]);
  const [targetId, setTargetId] = useState<number>(-1);
  //objective
  const [dataObjective, setDataObjective] = useState<CustomerGroupOutputModel[]>([]);
  const [objectiveId, setObjectiveId] = useState<number>(-1);
  //request type
  const [dataRequestType, setDataRequestType] = useState<CustomerGroupOutputModel[]>([]); //xai tam model
  const [requestTypeId, setRequestTypeId] = useState<number | null>(null);

  const [itemGroupType, setItemGroupType] = useState<ItemGroupOutputModel | null>(null);

  const [dataCustomer, setDataCustomer] = useState<AuthCustomer | null>(null);

  const [dataPathogen, setDataPathogen] = useState<ItemOutputModel[]>([]);

  const [dataSampleTypeGroup, setDataSampleTypeGroup] = useState<ItemGroupOutputModel[]>([]);
  const [profile, setProfile] = useState<any>([]);

  const [valid, setValid] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, {
    page: 0,
    totalPages: 0,
    limit: 0,
    totalRecords: 0,
    data: [],
    fetching: false,
    errorMessage: "",
    loading: true,
  });

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const loadProfileData = _.debounce(async () => {
    const response = await CustomerService.getAuthProfile();
    const dataCustomer: AuthCustomer = response.data?.customer?.find(
      (cus: any) => cus.source === SourceType.LAB
    );
    setProfile(response.data);
    setDataCustomer(dataCustomer);
  }, 100);

  useEffect(() => {
    loadProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderById: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getOrderById(orderId);
      if (mounted.current) {
        setOrder(() => response.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    orderById();
  }, []);

  const fetchData = async (query: any, isReset: boolean = false) => {
    if (state.fetching) return;
    dispatch({ type: Action.FETCH, payload: {} });

    try {
      const result = await ProductService.filterProducts(query);
      if (isReset) {
        dispatch({
          type: Action.RESET,
          payload: result.data,
        });
        if (!itemGroupType) {
          setItemGroupType(result.data?.itemGroups[0]);
        }
      } else if (mounted.current) {
        dispatch({
          type: Action.SUCCESS,
          payload: result.data,
        });
        if (!itemGroupType) {
          setItemGroupType(result.data?.itemGroups[0]);
        }
      }
    } catch (err) {
      if (mounted) {
        dispatch({
          type: Action.FAILED,
          payload: null,
        });
        setItemGroupType(null);
      }
    }
  };

  useEffect(() => {
    if (!!dataCustomer && targetId > -1 && objectiveId > -1) {
      fetchData(
        {
          page: 1,
          limit: 20,
          searchValue: searchValue,
          source: SourceType.LAB,
          itemGroupId: itemGroupType ? itemGroupType?.id : undefined,
          customerId: dataCustomer.id,
          targetId: targetId,
          objectiveId: objectiveId,
        },
        true
      );
    }
  }, [searchValue, interestedSuccess, dataCustomer, itemGroupType, targetId, objectiveId]);

  const onSearch = _.debounce((value) => {
    setSearchValue(value);
  }, 500);

  const hasMore = state.data.length < state.totalRecords && state.page < state.totalPages;

  const onFetchApiTarget: () => Promise<void> = async () => {
    try {
      const response = await TargetService.getAllTarget();
      if (mounted.current) {
        setDataTarget(() => response.data);
      }
    } catch (error) {}
  };

  const onFetchApiObjective: () => Promise<void> = async () => {
    try {
      const response = await ObjectiveService.getAllObjective();
      if (mounted.current) {
        setDataObjective(() => response.data);
      }
    } catch (error) {}
  };

  const onFetchApiRequestType: () => Promise<void> = async () => {
    try {
      const response = await OrderService.getRequestType();
      if (mounted.current) {
        setDataRequestType(() => response.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (order) {
      const newData = order.orders.map((val) => {
        const dataOrderline: ItemOutputModel[] = val.orderlines.map((o) => {
          return {
            ...o.item,
            itemGroupType: o.item.itemGroup,
            value: parseFloat(o.price),
          };
        });
        return {
          item: {
            id: val.id,
            condition: "N/A",
            material: "",
            validFrom: "",
            validTo: "",
            value: Number(val.orderlines[0].price),
            additionValue: "",
            baseUoM: "",
            note: "",
            source: "",
            conditionPath: "",
            currency: "",
            createdAt: "",
            updatedAt: "",
            salesOrg: null,
            priceType: null,
            dimension: "",
            item: dataOrderline[0],
            deleteAt: null,
            itemGroupType: { ...val.itemGroup },
            itemPathogen: dataOrderline,
            sampleId: val.sample.id,
          },
          quantity: val.quantity,
        };
      });

      setSelectedVars(newData);
      setObjectiveId(order?.objective?.id);
      setTargetId(order?.target.id);
      setRequestTypeId(order?.orders[0]?.requestType?.id);
      onFetchApiTarget();
      onFetchApiObjective();
      onFetchApiRequestType();

      // getCustomerById(order.orders[0]?.customer.id);
    }
  }, [order]);

  useEffect(() => {
    if (!!state.itemGroups?.length) {
      const itemGroup: ItemGroupOutputModel[] = state.itemGroups;
      setDataSampleTypeGroup(itemGroup);
    }
  }, [state.itemGroups]);

  const onUpdateProdVarQuantity = (quantity: number, addedProduct?: OrderProduct) => {
    if (!dialogID) {
      let data = [...tempSelectedVars];
      // thêm mới
      if (!addedProduct) {
        return;
      }
      const checkDataItem =
        !!data.length && data.findIndex((i) => i.item?.id === addedProduct?.id) > -1;
      if (checkDataItem) {
        data = data.filter((i) => i.item?.id !== addedProduct?.id);
        setTempSelectedVars(data);
        return;
      } else {
        const addPathogen = {
          ...addedProduct,
          itemPathogen: [addedProduct.item],
          itemGroupType: itemGroupType,
        };
        data.push({
          item: addPathogen,
          quantity,
        });
      }
      data = data.filter((item) => item.quantity >= 1);
      setTempSelectedVars(data);
    } else if (addedProduct) {
      const checkDataPathogen =
        !!dataPathogen.length && dataPathogen.findIndex((i) => i.id === addedProduct.item?.id) > -1;
      if (checkDataPathogen) {
        const newItem = dataPathogen.filter((i) => i.id !== addedProduct.item.id);
        setDataPathogen(newItem);
      } else {
        const newItem = dataPathogen.concat([{ ...addedProduct?.item, value: addedProduct.value }]);
        setDataPathogen(newItem);
      }
    }
  };

  const onAddDataSelect = () => {
    const combinedItems = tempSelectedVars.reduce((acc: ItemOutputModel[], current) => {
      const updatedItems: ItemOutputModel[] = !!current?.item?.itemPathogen?.length
        ? current?.item?.itemPathogen.map((item) => ({
            ...item,
            value: current?.item?.value,
          }))
        : [];

      return acc.concat(updatedItems);
    }, []);

    const newData = [
      {
        item: {
          ...tempSelectedVars[0]?.item,
          itemPathogen: combinedItems,
          itemGroupType: itemGroupType,
        },
        quantity: tempSelectedVars[0].quantity,
      },
    ];

    setSelectedVars([...selectedVars, ...newData]);
    setTempSelectedVars([]);
  };

  const onUpdateDataSelect = (index: number, quantity: number, sampleId?: number) => {
    if (!dialogID) {
      if (!sampleId) {
        let data = [...selectedVars];
        data[index] = {
          ...data[index],
          quantity: quantity || 1,
        };
        data = data.filter((item) => item.quantity >= 1);
        setSelectedVars(data);
      } else {
        let data = [...selectedVars];
        data[index] = {
          ...data[index],
          item: {
            ...data[index].item,
            sampleId: sampleId,
          },
        };
        data = data.filter((item) => item.quantity >= 1);
        setSelectedVars(data);
      }
    }
  };

  const onDuplicatePro = (index: number) => {
    if (!dialogID) {
      const dataDuplicate = [...selectedVars];
      dataDuplicate.splice(index, 0, dataDuplicate[index]);
      setSelectedVars(dataDuplicate);
      setTempSelectedVars([]);
    }
  };

  const onRemoveProdPathogen = (dataItem: ItemOutputModel, index: number) => {
    const dataSelect = [...selectedVars];
    dataSelect[index] = {
      ...dataSelect[index],
      item: {
        ...dataSelect[index].item,
        itemPathogen: dataSelect[index]?.item?.itemPathogen?.filter((e) => e.id !== dataItem.id),
      },
    };
    setSelectedVars(dataSelect);
  };

  const onAddProPathogen = () => {
    if (!!dialogID) {
      const dataSelect = [...selectedVars];
      dataSelect[(dialogID as number) - 1] = {
        ...dataSelect[(dialogID as number) - 1],
        item: {
          ...dataSelect[(dialogID as number) - 1].item,
          itemPathogen: dataPathogen,
        },
      };
      setSelectedVars(dataSelect);
      setDataPathogen([]);
      setDialogID(null);
    }
  };

  const bindingDataForm = (data: any) => {
    return {
      ...data,
      totalSample: order?.totalSample,
    };
  };

  const validProducts = selectedVars.filter((item) => item.quantity);

  /** Điều kiện check trùng: cùng tác nhân, phương pháp,loại mẫu */
  const findDataDuplicate = (array: { item: OrderProduct; quantity: number }[]) => {
    return array.filter((item1, index1) => {
      return array.some((item2, index2) => {
        if (index1 !== index2) {
          return (
            _.isEqual(
              item1.item?.itemPathogen?.map((i) => i.id),
              item2.item?.itemPathogen?.map((i) => i.id)
            ) &&
            _.isEqual(item1.item?.itemGroupType?.id, item2.item?.itemGroupType?.id) &&
            _.isEqual(item1.item?.sampleId, item2.item.sampleId)
          );
        }
        return false;
      });
    });
  };

  const submitForm = async (values: any) => {
    setLoading(true);
    const { note } = values;
    if (!validProducts.length) {
      setLoading(false);
      // setNotiOrderNull(true);
    }
    if (selectedVars.findIndex((i) => !i.item.sampleId) > -1) {
      setLoading(false);
      setValid(true);
    } else if (!requestTypeId) {
      setLoading(false);
      setValid(true);
    } else if (!Boolean(values.totalSample)) {
      setLoading(false);
      setValid(true);
    } else if (!!findDataDuplicate(validProducts).length) {
      setValid(true);
      setLoading(false);
      enqueueSnackbar(t("VALID_UPDATE_SAMPLE_TYPE_PATHOGEN"), {
        variant: "error",
      });
    } else {
      let total = 0;
      const orderDetails = validProducts.map((item) => {
        total += item.item.value * item.quantity;

        return {
          orderlineDetails: !!item?.item?.itemPathogen?.length
            ? item?.item?.itemPathogen.map((val) => {
                return {
                  itemId: val.id,
                  quantity: item.quantity,
                  unitPrice: checkPermissionViewPrice ? Number(val.value) : -1,
                  subTotal: checkPermissionViewPrice ? Number(val.value) * item.quantity : -1,
                  divisionId:
                    item.item?.item?.division?.id ||
                    dataCustomer?.customerDivision[0]?.division?.id,
                  saleUnit: val.saleUnit,
                  baseUoM: val.baseUoM,
                };
              })
            : [],

          itemGroupId: Number(item.item.itemGroupType?.id),
          sampleId: Number(item.item.sampleId),
          quantity: item.quantity,
          totalQuantity: validProducts.reduce((acc, curr) => acc + curr.quantity, 0),
          additionValue: 0,
          subTotal: checkPermissionViewPrice
            ? item?.item?.itemPathogen?.reduce(
                (acc, curr) => acc + Number(curr.value) * item.quantity,
                0
              )
            : -1,
        };
      });

      const data: OrderInputModel = {
        targetId: targetId,
        objectiveId: objectiveId,
        requestTypeId: requestTypeId,
        vat: 0,
        requiredDate: moment(values.requiredDate || new Date()).format("YYYY-MM-DD"),
        totalDiscount: 0,
        orderAmount: checkPermissionViewPrice ? total : -1,
        totalSample: Number(values.totalSample),
        source: SourceType.LAB,
        isDraft: ordDraft,
        draftId: 0,
        note,
        campId: Number(order?.orders[0].camp?.id),
        salesOrgId: Number(dataCustomer?.customerDivision[0].salesOrg?.id),
        distributionChannelId: Number(dataCustomer?.customerDivision[0].distributionChannel?.id),
        orderDetails: orderDetails as any,
      };

      OrderService.updateOrderLab(orderId, data as any)
        .then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            setPlaying(true);
            setLoading(false);
            enqueueSnackbar(t("ORDER_UPDATE_SUCCESSFUL"), {
              variant: "success",
            });
            history.push(APP_ROUTES.ORDER_DETAIL_LAB.replace(":orderId", orderId));
          } else {
            setLoading(false);
            enqueueSnackbar(t("ORDER_UPDATE_FAILED"), {
              variant: "error",
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          submitRef.current.disabled = false;
          enqueueSnackbar(t("ORDER_UPDATE_FAILED"), {
            variant: "error",
          });
        });

      setValid(false);
    }
  };

  const updateProductInterested = async (id: number, interested: boolean) => {
    try {
      const res = await ProductService.updateProductInterested({
        itemId: id,
        interested: interested,
        source: SourceType.LAB,
      });
      if (res && (res.status === 200 || res.status === 201)) {
        setInterestedSuccess(!interestedSuccess);
      }
    } catch (error: any) {}
  };

  const cancelOrderLab = async () => {
    if (submitRef.current) {
      submitRef.current.disabled = true;
    }
    try {
      await OrderService.cancelOrder({
        reasonStatus: Reason.OTHER,
        orderGroupId: +orderId,
        reason: "",
        source: SourceType.LAB,
      });
      enqueueSnackbar(t("ORDER_CANCELLED_SUCCESSFULLY"), {
        variant: "success",
      });
      history.push(APP_ROUTES.ORDER_LAB);
    } catch (error) {
      enqueueSnackbar(t("CANCEL_ORDER_FAILED"), {
        variant: "error",
      });
      setNotiOrderCancel(false);
      submitRef.current.disabled = false;
    }
  };

  //permission
  const checkPermissionViewPrice = PermissionUtil.check(
    profile?.role?.permissions,
    ItemPermission.MANAGE_ITEM_VIEW_PRICE,
    true
  );

  return (
    <Dialog fullScreen open={true}>
      <div className="order-modal">
        <SimpleModalHeader
          title={t("UPDATE_ORDER_LAB")}
          onClose={() => {
            history.goBack();
          }}
        />
        <div className="order-form">
          <Grid container>
            <Grid item xs={9} className="order-form-left">
              <Form
                onSubmit={async (values) => submitForm(values)}
                initialValues={bindingDataForm(order?.orders[0])}>
                {({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit} id="order-form">
                      {!!order && (
                        <div className="header">
                          <div className="col">
                            <div className="label">{t("FARM_NAME")}</div>
                            <span className="name">
                              {order.orders[0]?.camp?.name || TEXT_HOLDER}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="product-order">
                        <div className="product-order-header">
                          <div className="stt">{t("STT")}</div>
                          <div className="prod">{t("PATHOGEN")}</div>
                          <div className="unit">{t("TEST_METHOD")}</div>
                          <div className="quantity">{t("TEST_QUANTITY")}</div>
                          <div className="type">{t("SAMPLE_TYPE")}</div>
                          <div className="pay">{t("TOTAL_AMOUNT_ESTIMATED")}</div>
                          <div className="totalQuan">{t("TOTAL_QUANTITY")}</div>
                          <div className="ic-del"></div>
                        </div>
                        <Divider style={{ marginBottom: "3.3rem" }} />
                        <div className="product-order-list">
                          {!!selectedVars.length &&
                            selectedVars.map((val, index: number) => {
                              const subTotal =
                                val.item?.itemPathogen?.reduce(
                                  (acc, curr) => acc + Number(curr.value) * val.quantity,
                                  0
                                ) || 0;
                              const sampleType =
                                dataSampleTypeGroup?.find(
                                  (i) => i.id === val.item?.itemGroupType?.id
                                )?.samples || [];
                              const checkDuplicate =
                                findDataDuplicate(validProducts).findIndex(
                                  (i) => i.item?.id === val.item?.id
                                ) > -1;
                              return (
                                <div
                                  className="product-order-card"
                                  style={{ paddingBottom: "2.4rem" }}
                                  key={index + 1}>
                                  <div
                                    className="stt"
                                    style={{
                                      color: valid && checkDuplicate ? "#f44336" : "#312f30",
                                    }}>
                                    {index + 1}
                                  </div>

                                  <div className="prod">
                                    <div style={{ display: "flex" }}>
                                      {!!val.item?.itemPathogen && (
                                        <>
                                          <div className="name">
                                            {val.item?.itemPathogen[0]?.name?.split(" ")[0]}
                                            <div className="action">
                                              <ClearIcon
                                                className="clear-icon"
                                                style={{
                                                  width: "2rem",
                                                  color: "#272B2F",
                                                }}
                                                onClick={() =>
                                                  val.item?.itemPathogen &&
                                                  onRemoveProdPathogen(
                                                    val.item?.itemPathogen[0],
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          {val.item?.itemPathogen?.length > 1 && (
                                            <Tooltip
                                              title={val.item?.itemPathogen
                                                ?.slice(1)
                                                .map((i) => i.name)
                                                .join(", ")}
                                              placement="top-start">
                                              <div className="name" style={{ cursor: "pointer" }}>
                                                + {val.item.itemPathogen?.length - 1}...
                                              </div>
                                            </Tooltip>
                                          )}
                                        </>
                                      )}
                                    </div>
                                    <div
                                      className="prod-action"
                                      style={{ opacity: dialogID ? ".5" : "1" }}
                                      onClick={() => {
                                        if (!dialogID) {
                                          setDialogID(index + 1);
                                          val.item?.itemPathogen &&
                                            setDataPathogen(val.item?.itemPathogen);
                                          val.item?.itemGroupType &&
                                            setItemGroupType(val.item?.itemGroupType);
                                        }
                                      }}>
                                      + {t("ADD")}
                                    </div>
                                  </div>

                                  <div
                                    className="unit"
                                    style={{
                                      color: valid && checkDuplicate ? "#f44336" : "#312f30",
                                    }}>
                                    {val.item?.itemGroupType?.name || TEXT_HOLDER}
                                  </div>

                                  <div className="quantity">
                                    <QuantityBtnGroup
                                      disableMinusBtn={val.quantity <= 1}
                                      quantity={val.quantity}
                                      onChangeQuantity={(value) => {
                                        onUpdateDataSelect(index, value);
                                      }}
                                      style={{ maxWidth: "12rem", maxHeight: "3.5rem" }}
                                    />
                                  </div>

                                  <div className="type">
                                    <FormControl error={valid && !val.item?.sampleId}>
                                      <Select
                                        value={val.item?.sampleId || undefined}
                                        onChange={(e: any) => {
                                          onUpdateDataSelect(index, 1, e.target.value);
                                        }}
                                        fullWidth
                                        input={
                                          <OutlinedInput
                                            className="form-text-field"
                                            color="success"
                                          />
                                        }
                                        variant="outlined"
                                        style={{ width: "18rem" }}
                                        className="form-select-field"
                                        renderValue={() => {
                                          return !!val.item?.sampleId ? (
                                            sampleType.find((i) => i.id === val.item?.sampleId)
                                              ?.name
                                          ) : (
                                            <span style={{ color: "#9e9e9e" }}>
                                              {t("SELECT_BY_NAME", {
                                                NAME: t("SAMPLE_TYPE").toLowerCase(),
                                              })}
                                            </span>
                                          );
                                        }}
                                        displayEmpty
                                        MenuProps={{
                                          className: "select-menu-list",
                                        }}>
                                        {!!sampleType?.length &&
                                          sampleType.map((tar, index: number) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={tar.id}
                                                style={{
                                                  borderBottom: "0.1rem solid #EFEFEF",
                                                }}>
                                                {tar.name}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </FormControl>
                                  </div>

                                  <div className="pay">
                                    {checkPermissionViewPrice
                                      ? currencyFormat(subTotal)
                                      : TEXT_ASTERISK}
                                    &ensp;đ
                                  </div>

                                  <div className="totalQuan">
                                    {Number(val.item.itemPathogen?.length) * val.quantity}
                                  </div>
                                  <div className="ic-del">
                                    <div onClick={() => {}}>
                                      <ContentCopy
                                        className="copy-icon"
                                        style={{
                                          fontSize: "2rem",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => onDuplicatePro(index)}
                                      />
                                    </div>
                                    <div
                                      onClick={() => {
                                        onUpdateDataSelect(index, -1);
                                      }}>
                                      <DeleteOutlineIcon
                                        style={{
                                          fontSize: "2.2rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="footer">
                        <div className="payment">
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="flex-end"
                            spacing={6}>
                            <Grid item xs={4}>
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("TARGET")}</div>
                                <Select
                                  value={targetId || undefined}
                                  onChange={(e: any) => {
                                    setTargetId(e.target.value);
                                    setSelectedVars([]);
                                    setTempSelectedVars([]);
                                  }}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  className="form-select-field"
                                  disabled={!dataCustomer}
                                  renderValue={() => {
                                    return targetId > -1 ? (
                                      dataTarget.find((i) => i.id === targetId)?.name
                                    ) : (
                                      <span style={{ color: "#9e9e9e" }}>
                                        {t("SELECT_BY_NAME", {
                                          NAME: t("TARGET").toLowerCase(),
                                        })}
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {!!dataTarget?.length &&
                                    dataTarget.map((tar, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={tar.id}
                                          style={{
                                            borderBottom: "0.1rem solid #EFEFEF",
                                          }}>
                                          {tar.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                              <div className="form-item text-field">
                                <div className="form-label">{t("TOTAL_SAMPLES")}</div>
                                <Field
                                  name="totalSample"
                                  validate={FormUtil.composeValidators([
                                    FormUtil.Rule.pattern(REGEX.NUMBER_CHARACTER, {
                                      errorMessage: t("INVALID_NAME", {
                                        NAME: t("TOTAL_SAMPLES").toLowerCase(),
                                      }),
                                    }),
                                  ])}>
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        onChange={(e) => {
                                          input.onChange(e.target.value);
                                        }}
                                        placeholder={t("INPUT_BY_NAME", {
                                          NAME: t("TOTAL_SAMPLES").toLowerCase(),
                                        })}
                                        color="success"
                                        className="form-text-field form-sel-field"
                                        inputProps={{
                                          className: "input",
                                        }}
                                        autoComplete="off"
                                        helperText={meta.touched ? meta.error : ""}
                                        error={
                                          (valid && !input.value) || (meta.touched && meta.error)
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                            </Grid>
                            <Grid item xs={4} className="info-order">
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("OBJECTIVE")}</div>
                                <Select
                                  value={objectiveId || undefined}
                                  onChange={(e: any) => {
                                    setObjectiveId(e.target.value);
                                    setSelectedVars([]);
                                    setTempSelectedVars([]);
                                  }}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  className="form-select-field"
                                  disabled={!dataCustomer}
                                  renderValue={() => {
                                    return objectiveId > -1 ? (
                                      dataObjective.find((i) => i.id === objectiveId)?.name
                                    ) : (
                                      <span style={{ color: "#9e9e9e" }}>
                                        {t("SELECT_BY_NAME", {
                                          NAME: t("OBJECTIVE").toLowerCase(),
                                        })}
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {!!dataObjective?.length &&
                                    dataObjective.map((obj: any, index: number) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={obj.id}
                                          style={{
                                            borderBottom: "0.1rem solid #EFEFEF",
                                          }}>
                                          {obj.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                              <div className="form-item">
                                <div className="form-label">{t("APPOINTMENT_DATE")}</div>
                                <Field
                                  name="requiredDate"
                                  // validate={FormUtil.Rule.required(
                                  //   "Bạn vẫn chưa chọn ngày giao hàng"
                                  // )}
                                >
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <MuiPickersUtilsProvider locale={vi} utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                          okLabel={t("SELECT")}
                                          cancelLabel={t("CANCEL")}
                                          fullWidth
                                          disablePast
                                          disableToolbar
                                          InputProps={{ readOnly: true }}
                                          inputVariant="outlined"
                                          format="dd-MM-yyyy"
                                          className="form-text-field date"
                                          value={input.value || moment().format("YYYY-MM-DD")}
                                          onChange={(value) => {
                                            if (value) {
                                              input.onChange(value);
                                            }
                                          }}
                                          inputProps={{ className: "input" }}
                                          keyboardIcon={
                                            <EventIcon
                                              color="#41AD49"
                                              size={[22, 22]}
                                              viewBox={[22, 22]}
                                            />
                                          }
                                          helperText={meta.touched ? meta.error : ""}
                                          error={meta.error && meta.touched}
                                        />
                                      </MuiPickersUtilsProvider>
                                    );
                                  }}
                                </Field>
                              </div>
                            </Grid>
                            <Grid item xs={4} className="total">
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("REQUEST_TYPE")}</div>
                                <Select
                                  value={requestTypeId}
                                  onChange={(e: any) => {
                                    setRequestTypeId(e.target.value);
                                  }}
                                  fullWidth
                                  input={
                                    <OutlinedInput className="form-text-field" color="success" />
                                  }
                                  variant="outlined"
                                  className="form-select-field"
                                  renderValue={() => {
                                    return !!requestTypeId ? (
                                      dataRequestType.find((i) => i.id === requestTypeId)
                                        ?.description
                                    ) : (
                                      <span style={{ color: "#9e9e9e" }}>
                                        {t("SELECT_BY_NAME", {
                                          NAME: t("REQUEST_TYPE").toLowerCase(),
                                        })}
                                      </span>
                                    );
                                  }}
                                  displayEmpty
                                  MenuProps={{
                                    className: "select-menu-list",
                                  }}>
                                  {!!dataRequestType.length &&
                                    dataRequestType.map((i) => {
                                      return (
                                        <MenuItem
                                          key={i.id}
                                          value={i.id}
                                          style={{
                                            borderBottom: "0.1rem solid #EFEFEF",
                                          }}>
                                          {i.description}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </div>
                              <div className="form-item" style={{ width: "100%" }}>
                                <div className="form-label">{t("NOTES")}</div>
                                <Field name="note">
                                  {({ input, meta, ...rest }) => {
                                    return (
                                      <TextField
                                        {...input}
                                        {...rest}
                                        fullWidth
                                        placeholder={t("INPUT_BY_NAME", {
                                          NAME: t("NOTES").toLowerCase(),
                                        })}
                                        className="form-text-field"
                                        inputProps={{ className: "input" }}
                                        variant="outlined"
                                        onChange={(e) => input.onChange(e.target.value)}
                                        helperText={meta.touched ? meta.error : ""}
                                        error={meta.error && meta.touched}
                                        onKeyDown={(event) => {
                                          if (event.key === "Enter") {
                                            event.preventDefault();
                                            return false;
                                          }
                                        }}
                                      />
                                    );
                                  }}
                                </Field>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <div className={selectedVars.length ? "show" : "hide"}>
                          <div
                            className="btn-cancel-style btn__text-cancel"
                            onClick={() => {
                              history.push(APP_ROUTES.ORDER_LAB);
                            }}>
                            {t("CANCEL")}
                          </div>
                          {order?.status === OrderStatusLab.DRAFT && (
                            <button
                              disabled={!selectedVars?.length}
                              onClick={() => {
                                setOrdDraft(true);
                              }}
                              type="submit"
                              ref={submitRef}
                              className="btn-confirm-style btn-border btn--transparent"
                              style={{ marginLeft: "4rem" }}>
                              {t("SAVE_DRAFT")}
                            </button>
                          )}
                          <button
                            disabled={!selectedVars?.length}
                            onClick={() => {}}
                            type="submit"
                            ref={submitRef}
                            className="btn-confirm-style"
                            style={{ marginLeft: "4rem" }}>
                            {order?.status === OrderStatusLab.DRAFT ? t("SEND_REQUEST") : t("SAVE")}
                          </button>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </Grid>

            <Grid item xs={3} className="order-form-right">
              {targetId === -1 || objectiveId === -1 ? (
                <div className="new-order">
                  <img src={NewOrder} alt="new-order" />
                  <span style={{ fontWeight: 700, fontSize: "1.8rem", lineHeight: "2.7rem" }}>
                    {t("ENTER_CUSTOMER_TARGET_OBJECTIVE")}
                  </span>
                  <span>{t("START_CREATING_ORDER")}</span>
                </div>
              ) : (
                <>
                  <div className="content-right__title">{t("TEST_METHOD")}</div>
                  <TextField
                    variant="outlined"
                    color="success"
                    placeholder={t("SEARCH_BY_PATHOGEN")}
                    className={"search-border-style"}
                    inputProps={{ className: "input" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="large" />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value);
                    }}
                  />

                  {state.itemGroups && state.itemGroups.length > 1 && (
                    <div className="gr-item">
                      <Swiper
                        slidesPerView={3}
                        spaceBetween={1}
                        navigation={{
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                        }}>
                        {[...state.itemGroups].map((cate: any) => {
                          return (
                            <SwiperSlide>
                              <div
                                className={
                                  cate?.id === itemGroupType?.id
                                    ? "btn-gr__item active btn--shiny "
                                    : "btn-gr__item"
                                }
                                onClick={() => {
                                  !tempSelectedVars.length && !dialogID && setItemGroupType(cate);
                                }}
                                style={{
                                  cursor:
                                    !tempSelectedVars.length && !dialogID
                                      ? "pointer"
                                      : "not-allowed",
                                }}>
                                <div className="label">{cate?.name.toLowerCase()}</div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                      <button className="swiper-button-prev button-prev">
                        <ArrowLeftSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
                      </button>
                      <button className="swiper-button-next button-next">
                        <ArrowRightSharpIcon style={{ color: "#5A5959", fontSize: "2rem" }} />
                      </button>
                    </div>
                  )}

                  {state.loading || (state.fetching && (searchValue || itemGroupType)) ? (
                    <Loading style={{ margin: "2rem 0", height: "60vh" }} />
                  ) : (
                    <>
                      <div className="product-list">
                        {/* {state.fetching && searchValue && <Loading />}
                        {state.fetching && itemGroupType && <Loading />} */}
                        {state.data?.length > 0 ? (
                          <InfiniteScroll
                            pageStart={0}
                            loadMore={() =>
                              fetchData({
                                page: state.page + 1,
                                limit: 20,
                                source: SourceType.LAB,
                                searchValue,
                                itemGroupId: itemGroupType ? itemGroupType?.id : undefined,
                              })
                            }
                            hasMore={hasMore}
                            useWindow={false}>
                            {state.data.map((pro: any, index: number) => {
                              const act = dataPathogen.find((item) => item.id === pro.item.id);

                              const actItem = !!tempSelectedVars.length
                                ? tempSelectedVars?.find((item) =>
                                    item?.item?.itemPathogen?.find((i) => i.id === pro.item.id)
                                  )
                                : null;

                              return (
                                <ProductItem
                                  class={!!dialogID ? act : actItem}
                                  key={pro.item.code + index}
                                  id={pro.item.id}
                                  onClick={() => onUpdateProdVarQuantity(1, pro)}
                                  image={pro.item.images?.picture}
                                  shortName={pro.item.name}
                                  name={pro.item.shortName}
                                  value={
                                    checkPermissionViewPrice
                                      ? currencyFormat(pro.value)
                                      : TEXT_ASTERISK
                                  }
                                  baseUoM={pro.baseUoM}
                                  source={SourceType.LAB}
                                  interestedItem={pro.item.interestedItem}
                                  updateProductInterested={(interested: boolean) =>
                                    updateProductInterested(pro.item.id, interested)
                                  }
                                  disableImg
                                  color={pro.item?.color}
                                />
                              );
                            })}
                          </InfiniteScroll>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}>
                            <img src={ProNull} alt="search" width={190} height={178} />
                            <div
                              style={{
                                fontSize: "1.8rem",
                                fontWeight: "bold",
                                color: "#536380",
                                lineHeight: "2.7rem",
                              }}>
                              {t("PRODUCT_NOT_FOUND")}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="product-list_footer">
                        <div className="action">
                          <button
                            onClick={() => {
                              setDataPathogen([]);
                              setTempSelectedVars([]);
                              setDialogID(null);
                            }}
                            type="submit"
                            style={{
                              width: "20rem",
                              opacity:
                                !!dataPathogen.length || !!tempSelectedVars?.length ? "1" : "0.2",
                            }}
                            className="btn-confirm-style btn-border">
                            {t("CANCEL")}
                          </button>
                          <button
                            onClick={() =>
                              !!dataPathogen.length
                                ? onAddProPathogen()
                                : !!tempSelectedVars?.length && onAddDataSelect()
                            }
                            type="submit"
                            className="btn-confirm-style"
                            style={{
                              marginLeft: "4rem",
                              width: "20rem",
                              opacity:
                                !!dataPathogen.length || !!tempSelectedVars?.length ? "1" : "0.2",
                            }}>
                            {t("SELECT")}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>

        {loading && (
          <Backdrop
            sx={{ color: "red", opacity: 0.5, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <Loading />
          </Backdrop>
        )}

        <Notification
          visible={notiOrderCancel}
          title={t("CANCEL_CART")}
          subTitle={
            <span dangerouslySetInnerHTML={{ __html: t("ARE_YOU_SURE_YOU_WANT_CANCEL_CART") }} />
          }
          icon={
            <div className={`notification-icon-box yellow`}>
              <PriorityHighRoundedIcon style={{ fontSize: "7rem", color: "#fff" }} />
            </div>
          }
          actions={
            <>
              <button
                className="btn-confirm-style"
                style={{
                  background: "#fff",
                  color: "#0A6836",
                  border: "0.1rem solid #0A6836",
                  width: "20.6rem",
                  marginRight: "2rem",
                }}
                onClick={() => {
                  setNotiOrderCancel(false);
                }}>
                {t("NO")}
              </button>
              <button
                className="btn-confirm-style"
                style={{
                  width: "20.6rem",
                  marginLeft: "2rem",
                }}
                onClick={() => {
                  cancelOrderLab();
                }}>
                {t("CONFIRM")}
              </button>
            </>
          }
          onClose={() => {
            setNotiOrderCancel(false);
          }}
        />
      </div>
    </Dialog>
  );
};

export default UpdateOrderLab;
